import axios from 'axios'
import {withFormik} from 'formik'
import React from 'react'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {Debug} from '../lib/formik-debug'
import {ErrorMessage, getClasses} from '../lib/helpers'
import {Button, ButtonWrapper, Desc, Form, FormGroup, Heading, Input, InputWrap, Label, Redirect, StyledMaskedInput} from './summer-2020-form-sytle'
import Summer2020RegistrationForm from './summer-2020-registration-form'
import Summer2020Registered from './summer-2020-registered'
import Toastify from './Toastify'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'

const notifyError = () => toast.error('お申し込み番号をご確認の上、\nページを再読込してから送信してください。', {containerId: 'Error'})

const applicationNumMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const InnerForm = props => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
    <>
      <Form onSubmit={handleSubmit} key='getClass'>
        <Heading>クラスを検索</Heading>

        <FormGroup className='applicationNum'>
          <InputWrap>
            <Input
              name='applicationNum'
              render={({field}) => (
                <StyledMaskedInput
                  {...field}
                  mask={applicationNumMask}
                  id='phone'
                  type='text'
                  value={values.applicationNum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${touched.applicationNum ? 'is-touched' : ''} ${touched.applicationNum && errors.applicationNum ? 'is-invalid' : 'is-valid'} ${touched.applicationNum && values.applicationNum === '' ? 'is-empty' : ''}`}
                />
              )}
            />
            <Label className='form-control-label'>お申し込み番号</Label>
          </InputWrap>
          <Desc>16桁のお申し込み番号を半角数字で入力してください。</Desc>
          <ErrorMessage name='name' />
        </FormGroup>

        <ButtonWrapper>
          <Button
            type='submit'
            className='btn btn-outline-primary'
            disabled={isSubmitting}
          >
            {isSubmitting ? '送信中...' : 'クラスを表示する'}
          </Button>
          {isSubmitting && <Redirect>クラス情報の取得中です。そのままお待ちください。</Redirect>}
        </ButtonWrapper>

        {/* <Debug /> */}

        <Toastify
          enableMultiContainer
          containerId='Success'
          position='top-right'
          autoClose={8000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
          className='myToast'
        />
        <Toastify
          enableMultiContainer
          containerId='Error'
          position='top-right'
          autoClose={8000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
          className='myToast'
        />

      </Form>
      {values.grade && <Grade>{values.grade}</Grade>}
      {values.classes &&
       Object.keys(values.classes).map(day => {
         return values.classes[day].map((el, i) => {
           const meetUrl = el['Meet Url'] || '準備中'
           const time = el['hh:mm'] || ''
           const name = el.Name || ''
           const weekday = el.Weekday[0] || ''
           return (
             <DayWrap key={el.airtableId}>
               {i === 0 && <Day>{day}({weekday})</Day>}
               <Time>{time}</Time>
               <Class>{name}</Class>
               {meetUrl === '準備中' && <MeetWrap>Meet URL 準備中</MeetWrap>}
               {meetUrl !== '準備中' && <MeetWrap><MeetUrl href={meetUrl}>{meetUrl}</MeetUrl> </MeetWrap>}
             </DayWrap>
           )
         })
       })}
    </>
  )
}

const Summer2020GetClassesForm = withFormik({
  mapPropsToValues: () => ({
    applicationNum: '',
    studentId: '',
    student: {},
    classes: '',
    registered: false,
    meetUrl: []
  }),

  handleSubmit: async (values, {props, setValues, setSubmitting, setStatus, validateForm}) => {
    await validateForm().then(res => {
      setStatus({state: 'Submitting'})
      axios.request({
        method: 'POST',
        baseURL: '/api/registration/getClassesRegistrationTerm2',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        data: JSON.stringify({
          applicationNum: values.applicationNum
        })
      })
        .then((res) => {
          console.log(JSON.stringify(res.data, null, 2))
          setValues({...values, classes: res.data.classes, grade: res.data.grade})
          setSubmitting(false)
          setStatus({state: 'Success'})
        }).catch((error) => {
          console.log(error)
          notifyError()
          setSubmitting(false)
          setStatus({state: 'Error'})
        })
    }).catch(err => {
      console.log(err)
    })
  },

  validationSchema: Yup.object().shape({
    applicationNum: Yup.string().matches(/^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/, '16桁のお申し込み番号を入力してください')
  })
})(InnerForm)

const DayWrap = styled.div`
  margin: 0 0 1.6rem 0;
`

const Day = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.navy};
  margin: 4rem 0 0.8rem 0;
  display: block;
`

const Grade = styled.span`
  font-size: 2.0rem;
  font-weight: 700;
  color: ${colors.navy};
  margin: 0 0 0.8rem 0;
  display: block;
`

const Time = styled.span`
  font-size: 1.6rem;
  color: ${colors.black};
  display: block;
`

const Class = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${colors.lightBlack};
  /* margin: 0 0 0.8rem 0; */
  display: block;
`

const MeetWrap = styled.span`
  display: inline;
  margin: 0 0 0.8rem 0;
  font-size: 1.6rem;
  color: ${colors.lightBlack};
  font-weight: 500;
`

const MeetUrl = styled.a`
  color: ${colors.lightNavy};
  display: inline;
  font-weight: 500;
`

export default Summer2020GetClassesForm
